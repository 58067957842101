import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import $router from '@/router'

// const baseURL = 'https://api.derek.co/api'
const baseURL = 'https://api.devderek.com.co/api'
// const baseURL = 'http://192.168.208.190:8085/api'
axios.defaults.baseURL = baseURL

function getClientId() {
  const cookies = document.cookie.split(';')
  for (let cookie of cookies) {
    const parts = cookie.split('=')
    if (parts[0].trim() === '_ga') {
      const clientId = parts[1]
        .split('.')
        .slice(2)
        .join('.')
      return clientId
    }
  }
  return null
}

function getSessionId() {
  const cookies = document.cookie.split(';')
  for (let cookie of cookies) {
    const parts = cookie.split('=')
    if (parts[0].trim() === '_ga_L8XG5NN0XL') {
      const sessionId = parts[1].split('.').slice(2)[0]
      return sessionId
    }
  }
  return null
}

axios.interceptors.request.use(
  config => {
    const misCookies = document.cookie.split(';')
    let email = misCookies.find(e => {
      return e.includes('email')
    })
    config.headers.stsgpath = window.location.pathname
    config.headers.stsgquery = window.location.search
    config.headers.tokencart = window.localStorage.getItem('tokenCart')
    config.headers.tokenwhislist = window.localStorage.getItem('tokenFavorite')
    config.headers.stsgsid = window.localStorage.getItem('stsg_sid')
    config.headers.gaclientid = getClientId()
    config.headers.gasessionid = getSessionId()
    config.headers.stsgemail = email
    for (var index = 0; index < misCookies.length; index++) {
      let busca = misCookies[index].search('_fbp')
      if (busca > -1) {
        config.headers.stsgfbp = misCookies[index].split('=')[1]
      }
      let busca2 = misCookies[index].search('_fbc')
      if (busca2 > -1) {
        config.headers.stsgfbc = misCookies[index].split('=')[1]
      }
    }
    if (!$router.app._route.params.tokenCart) {
      const token = window.localStorage.getItem('accessToken')
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  err => {
    return Promise.reject(err)
  },
)

Vue.use(VueAxios, axios)
